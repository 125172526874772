import React from 'react'
import { Form } from 'react-final-form'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import createDecorator from 'final-form-calculate'
import validator from 'validator'

import NewOrderWrapper from './NewOrderWrapper'

import { openSnackbar } from './../../reusable/Notifier'

import BillingAddress from '../../forms/BillingAddress'

const GET_PARKED_ORDER = gql`
  query parkedOrder($parkedOrderId: String!) {
    parkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      address_matches_residence
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
      first_name
      last_name
      address_line_1
      address_city
      address_county
      address_postcode
      address_line_2
      title
    }
  }
`

const UPDATE_PARKED_ORDER_BILLING_DETAILS = gql`
  mutation updateParkedOrderBillingDetails(
    $parkedOrderId: String!
    $addressMatchesResidence: Boolean!
    $billingFirstName: String!
    $billingLastName: String!
    $billingAddressLine1: String!
    $billingAddressCity: String!
    $billingAddressCounty: String!
    $billingAddressPostcode: String!
    $billingAddressLine2: String
    $billingTitle: String!
  ) {
    updateParkedOrderBillingDetails(
      parkedOrderId: $parkedOrderId
      addressMatchesResidence: $addressMatchesResidence
      billingFirstName: $billingFirstName
      billingLastName: $billingLastName
      billingAddressLine1: $billingAddressLine1
      billingAddressCity: $billingAddressCity
      billingAddressCounty: $billingAddressCounty
      billingAddressPostcode: $billingAddressPostcode
      billingAddressLine2: $billingAddressLine2
      billingTitle: $billingTitle
    ) {
      _id
      address_matches_residence
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
    }
  }
`

export default function BillingDetails({ match: { params }, history }) {
  const [updateParkedOrderBillingDetails, { loading: updateLoading }] =
    useMutation(UPDATE_PARKED_ORDER_BILLING_DETAILS, {
      onError: () => {
        openSnackbar({
          message: 'Failed to update billing details, please try again',
          type: 'error',
        })
      },
      onCompleted: () => {
        history.push(`/new-order/order-review/${params.parkedOrderId}`)
      },
    })

  const { loading, error, data } = useQuery(GET_PARKED_ORDER, {
    variables: { parkedOrderId: params.parkedOrderId },
  })

  let initialValues = {}

  if (data) {
    const { parkedOrder } = data

    //set initial values to ones received in for the parked order

    initialValues = {
      addressMatchesResidence: parkedOrder.address_matches_residence,
      billingFirstName: parkedOrder.billing_first_name,
      billingLastName: parkedOrder.billing_last_name,
      billingAddressLine1: parkedOrder.billing_address_line_1,
      billingAddressCity: parkedOrder.billing_address_city,
      billingAddressCounty: parkedOrder.billing_address_county,
      billingAddressPostcode: parkedOrder.billing_address_postcode,
      billingAddressLine2: parkedOrder.billing_address_line_2,
      billingTitle: parkedOrder.billing_title,
      firstName: parkedOrder.first_name,
      lastName: parkedOrder.last_name,
      addressLine1: parkedOrder.address_line_1,
      addressCity: parkedOrder.address_city,
      addressCounty: parkedOrder.address_county,
      addressPostcode: parkedOrder.address_postcode,
      addressLine2: parkedOrder.address_line_2,
      title: parkedOrder.title,
    }
  }

  return (
    <Form
      onSubmit={(values) => {
        updateParkedOrderBillingDetails({
          variables: {
            parkedOrderId: params.parkedOrderId,
            addressMatchesResidence: values.addressMatchesResidence,
            billingFirstName: values.billingFirstName,
            billingLastName: values.billingLastName,
            billingAddressLine1: values.billingAddressLine1,
            billingAddressCity: values.billingAddressCity,
            billingAddressCounty: values.billingAddressCounty,
            billingAddressPostcode: values.billingAddressPostcode,
            billingAddressLine2: values.billingAddressLine2,
            billingTitle: values.billingTitle,
          },
        })
      }}
      validate={(values) => {
        const errors = {}

        const requiredFields = [
          'billingTitle',
          'billingFirstName',
          'billingLastName',
          'billingAddressLine1',
          'billingAddressCity',
          'billingAddressCounty',
          'billingAddressPostcode',
        ]

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = 'Required'
          }
        })

        if (
          values.billingAddressPostcode &&
          (!validator.isPostalCode(values.billingAddressPostcode, ['GB']) ||
            values.billingAddressPostcode.trim().length <= 4)
        ) {
          errors.billingAddressPostcode = 'Invalid Postcode'
        }
        return errors
      }}
      initialValues={initialValues}
      decorators={[
        createDecorator(
          {
            // when choosing a billing addres, match the dropdown to the fields
            field: 'chosenBillingAddress',
            updates: async (value, name, allValues) => {
              if (value) {
                const address = value.split(',')

                return {
                  billingAddressLine1: address[0].trim(),
                  billingAddressLine2: address[1].trim(),
                  billingAddressCity: address[2].trim(),
                  billingAddressCounty: address[3].trim(),
                  billingAddressPostcode: address[4].trim(),
                }
              }

              return {}
            },
          },
          {
            //match billing address to customer address
            field: 'addressMatchesResidence',
            updates: async (value, name, allValues, oldValues) => {
              const firstLoad = Object.entries(oldValues).length === 0

              if (value) {
                return {
                  billingFirstName: allValues.firstName,
                  billingLastName: allValues.lastName,
                  billingAddressLine1: allValues.addressLine1,
                  billingAddressCity: allValues.addressCity,
                  billingAddressCounty: allValues.addressCounty,
                  billingAddressPostcode: allValues.addressPostcode,
                  billingAddressLine2: allValues.addressLine2,
                  billingTitle: allValues.title,
                }
              } else if (!firstLoad) {
                return {
                  billingFirstName: '',
                  billingLastName: '',
                  billingAddressLine1: '',
                  billingAddressCity: '',
                  billingAddressCounty: '',
                  billingAddressPostcode: '',
                  billingAddressLine2: '',
                  billingTitle: '',
                }
              }

              return {}
            },
          }
        ),
      ]}
      render={({ handleSubmit }) => (
        <NewOrderWrapper
          pageNumber={4}
          nextButtonLabel="Next"
          loading={loading}
          nextButtonLoading={updateLoading}
          nextPageFunction={() => {
            handleSubmit()
          }}
          error={
            error
              ? 'Failed to get billing details, please refresh the page.'
              : null
          }
        >
          <form autoComplete="off">
            <BillingAddress />
          </form>
        </NewOrderWrapper>
      )}
    />
  )
}
